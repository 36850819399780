.grid.recent {
  margin-bottom: 20px;
}

.grid.recent .grid-item {
  background-color: #FFDAB9;
}

.grid.recent .grid-item:hover {
  background-color: #FFC4A6;
}

.empty-message {
  text-align: center;
  font-size: 1.2rem;
  color: #757575;
  margin-bottom: 20px;
}

@font-face {
  font-family: 'Dovemayo_wild';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/Dovemayo_wild.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.dovemayo-wild {
  font-family: 'Dovemayo_wild', sans-serif;
}