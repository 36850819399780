.search-bar {
    margin: 1rem 0;
    padding-left: 0;
    box-sizing: border-box;
  }
  
  .search-bar input {
    width: auto;
    min-width: 150px;
    padding: 0.5rem;
    border: 2px solid #FFA07A;
    border-radius: 20px;
    font-size: 1rem;
    color: #333;
    background-color: #fff;
    transition: all 0.3s ease;
  }
  
  .search-bar input::placeholder {
    color: #999;
  }
  
  .search-bar input:focus {
    outline: none;
    border-color: #FFA07A;
    box-shadow: 0 0 8px rgba(255, 160, 122, 0.3);
  }
  
  .search-bar-container {
    display: flex;
    justify-content: center;
  }