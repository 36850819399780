.copied-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px; /* 추가된 부분 */
  height: 30px; /* 추가된 부분 */
  line-height: 30px; /* 추가된 부분 */
  text-align: center; /* 추가된 부분 */
  background-color: #ff80ab;
  color: white;
  padding: 0; /* 변경된 부분 */
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
}

@font-face {
  font-family: 'Dovemayo_wild';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/Dovemayo_wild.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.dovemayo-wild {
  font-family: 'Dovemayo_wild', sans-serif;
}