.new-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
}

.new-container h2 {
  font-family: 'Dovemayo_wild', sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

.new-container h3 {
  font-family: 'Dovemayo_wild', sans-serif;
  font-size: 22px; /* 크기를 18px에서 20px로 변경 */
  margin-bottom: 5px;
}

.new-container ol,
.new-container ul {
  margin-bottom: 15px;
}

.new-container p {
  font-size: 15px; /* 크기를 14px에서 16px로 변경 */
  text-align: center;
}