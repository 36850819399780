h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  background-color: #F8F8FF;
}

.grid-item:hover {
  background-color: #e0e0ff;
}

.grid-item span {
  display: block;
  text-align: center;
  font-size: 0.8rem;
  width: 80%; /* 추가된 속성 */
}

hr {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  border: none;
  margin: 5px 0;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(10, 1fr);
  }
}

@media (max-width: 767px) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@font-face {
  font-family: 'Dovemayo_wild';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/Dovemayo_wild.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  }
  
  .dovemayo-wild {
  font-family: 'Dovemayo_wild', sans-serif;
  }