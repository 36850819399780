.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #F9FAFB;
  }
  
  .logo-container {
    text-align: center;
  }
  
  .logo {
    width: auto;
    height: auto;
    max-width: 204px; /* 로고 너비를 조정합니다. 원하는 크기로 변경해주세요. */
    max-height: 136px; /* 로고 높이를 조정합니다. 원하는 크기로 변경해주세요. */
  }
  
  h2.subtitle {
    font-size: 1rem;
    margin-bottom: 5px;
    text-align: center;
  }