body {
  font-family: "Noto Sans KR", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px;
  flex-grow: 1;
}

.symbol-grid h2 {
  margin: 1rem 0;
  font-size: 1.5rem;
}