.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #F9FAFB;
}

.contact-text {
  font-size: 14px;
  color: #757575;
}

.contact-email {
  font-size: 14px;
  color: #757575;
  text-decoration: none;
  margin-left: 5px;
}

.contact-email:hover {
  color: #1a73e8;
}

.copyright-text {
  font-size: 14px;
  color: #757575;
  margin-top: 5px;
}