.categories {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .categories button {
    background-color: #FFA07A;
    color: white;
    padding: 9px 18px;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 0;
    transition-duration: 0.4s;
    flex-grow: 1;
  }
  
  .categories button:hover {
    background-color: white;
    color: #FFA07A;
    border: none;
  }
  
  .categories button:not(:last-child) {
    border-right: 1px solid white;
  }